// Logic
import './modules/layout';
import './modules/contact';
import './modules/scrolling';

// Styling Packages
import 'jquery-parallax.js';
import 'jquery-visible';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'normalize.css/normalize.css';
import 'animate.css';

// Custom Styling
import './styles/index.scss';
import './assets/images/favicon.png';
