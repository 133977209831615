function scrolling() {
  // About Us
  document.querySelector('.scroll-arrow').addEventListener('click', function(){
    let element = document.querySelector('.about-us-section');
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  });

  // Let Us Contact You
  document.querySelector('#enrolChildBtn').addEventListener('click', () => {
    let element = document.querySelector('.let-us-contant-you-form');
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  });

  // Let Us Contact You
  document.querySelector('#enrolChildBtn2').addEventListener('click', () => {
    let element = document.querySelector('.let-us-contant-you-form');
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  });

  // Subjects & Services
  document.querySelector('#subjectsAndServicesBtn').addEventListener('click', () => {
    let element = document.querySelector('.subjects-and-services-section');
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  });

  // Our Rates
  document.querySelector('#ourRatesBtn').addEventListener('click', () => {
    let element = document.querySelector('.our-rates-section');
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  });

  // To top
  document.querySelector('.scroll-arrow-top').addEventListener('click', function(){
    let element = document.querySelector('.hero-section');
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  });
}
export default scrolling();