function layout() {
  let vh = window.innerHeight * 0.01;
  let ua = window.navigator.userAgent;
  let msie = ua.indexOf('MSIE ');
  let trident = ua.indexOf('Trident/');
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // On Load
  window.onload = function () {
    if (msie > 0 || trident > 0) {
      document.querySelector('.outdated-browser-page').style.display = "block";
      setTimeout(() => {
        document.querySelector('.loader').classList.add('loaded');
      }, 500);

      setTimeout(() => {
        document.querySelector('.loader').style.display = 'none';
      }, 1000);
      return;
    }

    document.querySelector('.containing-wrapper').style.display = 'block';
    document.querySelector('.loader').classList.add('loaded');

    setTimeout(() => {
      document.querySelector('.loader').style.display = 'none';
    }, 500);

    // calcLogoWrapperHeight();
  }

  // On Resize
  window.addEventListener("resize", function() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === false){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // calcLogoWrapperHeight();

    let imageHeight = document.querySelector('.infographic-container').clientHeight;
    window.innerWidth >= 768
      ? document.querySelector('#desktopImg').style.height = (imageHeight + 16) + 'px'
      : document.querySelector('#mobileImg').style.height = (imageHeight + 16) + 'px';
  });
}

// function calcLogoWrapperHeight() {
//   let navHeight = document.querySelector('#navbar').clientHeight;
//   let landingHeight = document.querySelector('.hero-section').clientHeight;

//   document.querySelector('.logo-wrapper').style.height = `${landingHeight - navHeight}px`;
// }
export default layout();