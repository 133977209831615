import { initializeApp, database } from 'firebase/app';
import 'firebase/database';
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.za';

function contactUs() {
  new Cleave('#phone', {
    phone: true,
    phoneRegionCode: 'ZA'
  });

  new Cleave('#time', {
    time: true,
    timePattern: ['h', 'm']
  });

  let firebaseConfig = {
    apiKey: "AIzaSyC6i3tR3XuxyQBsshV64v0WtOpmI1asGmo",
    authDomain: "hellagood-web.firebaseapp.com",
    databaseURL: "https://hellagood-web.firebaseio.com",
    projectId: "hellagood-web",
    storageBucket: "hellagood-web.appspot.com",
    messagingSenderId: "394843518960",
    appId: "1:394843518960:web:57e30cf21db57b6c"
  };

  initializeApp(firebaseConfig);
  document.getElementById('contactForm').addEventListener('submit', submitForm);

  const messagesRef = database().ref('leads');

  // Submit Form
  function submitForm(e) {
    e.preventDefault();

    // Get Form Values
    let contactMethod = document.querySelector('input[name="coption"]:checked').value
    let contactNumber = getInputValue('phone');
    let contactTime = getInputValue('time');
    let emailAddress = getInputValue('email');
    let firstName = getInputValue('name');
    let lastName = getInputValue('surname');
    let message = getInputValue('message');

    // Check
    let checkForm = getInputValue('tjekkit');

    // Save message
    saveMessage(contactMethod, contactNumber, contactTime, emailAddress, firstName, lastName, message, checkForm);

    // Clear form & Reset Recaptcha Move Into Own Function
    document.getElementById('contactForm').reset();
    grecaptcha.reset();
    $('#submit').attr('disabled', true);
  }

  function getInputValue(id) {
    return document.getElementById(id).value;
  }

  function saveMessage(cmx, cnx, ctx, eax, fnx, lnx, msg, ckx) {
    if (ckx.length > 1) {
      return;
    }

    let newMessageRef = messagesRef.push();
    newMessageRef.set({
      personalInfo: {firstName: fnx, lastName: lnx},
      contactDetails: {emailAddress: eax, contactNumber: cnx, contactTime: ctx},
      contactMethod: cmx,
      message: msg,
      messageRead: false
    }, function(error) {
      if (error) {
        $('#toastSad').addClass('visible bounceInUp');
        setTimeout(() => {
          $('#toastSad').removeClass('bounceInUp');
          $('#toastSad').addClass('bounceOutDown');
        }, 5000);
      } else {
        $('#toastHappy').addClass('visible bounceInUp');
        setTimeout(() => {
          $('#toastHappy').removeClass('bounceInUp');
          $('#toastHappy').addClass('bounceOutDown');
        }, 5000);
      }
    });
  }

  // ReCaptcha
  function verifyRecaptcha() {
    if (grecaptcha.getResponse() == ""){
      console.error("Something seems fishy...");
    } else {
      $('#submit').attr('disabled', false);
    }
  }
  window.verifyRecaptcha = verifyRecaptcha;
}
export default contactUs();